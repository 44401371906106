import React from "react";
import Popup from "reactjs-popup";
import { Image, Button } from "react-bootstrap";

import imageClose from "../images/close.png";
import { facebookMessageLink, emailLink } from "../hooks/constant";

const PopUp = (props) => (
  <Popup
    open={props.open}
    closeOnDocumentClick
    // lockScroll={true}
    onClose={props.closeModal}
    overlayStyle={{ backgroundColor: "#00000066" }}
  >
    <div className="modal" style={{ backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src={imageClose}
          style={ImageCloseStyle}
          onClick={props.closeModal}
        />
        <p style={TitleTextStyle}>聯絡我們</p>
        <p style={ContentTextStyle}>您可以選擇以下方式與我們聯繫！</p>
        <Button
          variant="success"
          style={SendMessagebuttonStyle}
          onClick={() => {
            window.open(facebookMessageLink);
          }}
        >
          透過Facebook訊息
        </Button>
        <Button
          variant="success"
          style={{
            ...SendMessagebuttonStyle,
            marginTop: 15,
            backgroundColor: "#50BE00",
            marginBottom: 30,
          }}
          onClick={() => {
            window.open(emailLink);
          }}
        >
          透過電子郵件
        </Button>
      </div>
    </div>
  </Popup>
);

export default PopUp;

const SendMessagebuttonStyle = {
  marginTop: 30,
  marginLeft: 48,
  marginRight: 48,
  width: 150,
  height: 35,
  backgroundColor: "#3665D8",
  borderRadius: 6,
  borderWidth: 0,
  fontFamily: "PingFangTC-Medium",
  fontSize: 14,
  color: "white",
};

const ImageCloseStyle = {
  position: "absolute",
  width: 20,
  height: 20,
  top: 20,
  right: 20,
};

const TitleTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Medium",
  fontSize: 16,
  textAlign: "start",
  marginLeft: 0,
  marginTop: 26,
  marginBottom: 10,
};

const ContentTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Regular",
  fontSize: 13,
  textAlign: "start",
  margin: 0,
};
