import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import logo from "../images/logo.png";
import imageApple from "../images/appstore.png";
import imageGoogle from "../images/googleplay.png";
import imageFacebook from "../images/facebook.png";
import { googleLink, facebookLink, appleLink } from "../hooks/constant";

const Header = () => {
  const [downloadHidden, setDownloadHidden] = useState(window.innerWidth < 700);
  const [facebookHidden, setFacebookHidden] = useState(window.innerWidth < 350);

  const handleRWD = () => {
    setDownloadHidden(window.innerWidth < 700);
    setFacebookHidden(window.innerWidth < 350);
  };

  useEffect(() => {
    window.addEventListener("resize", handleRWD);
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  return (
    <div style={HeaderDivStyle}>
      <div
        style={{ marginLeft: "5%", flex: 1, display: "flex", marginRight: 20 }}
      >
        <Image src={logo} style={{ width: 160, height: 45, marginLeft: 25 }} />
      </div>
      <div style={{ marginRight: "5%" }}>
        <Image
          src={imageFacebook}
          style={{ width: 114, height: 35, marginRight: 20 }}
          hidden={facebookHidden}
          onClick={() => {
            window.open(facebookLink);
          }}
        />
        <Image
          src={imageApple}
          style={{ width: 114, height: 35, marginRight: 20 }}
          hidden={downloadHidden}
          onClick={() => {
            window.open(appleLink);
          }}
        />
        <Image
          src={imageGoogle}
          style={{ width: 114, height: 35, marginRight: 25 }}
          hidden={downloadHidden}
          onClick={() => {
            window.open(googleLink);
          }}
        />
      </div>
    </div>
  );
};

export default Header;

const HeaderDivStyle = {
  position: "fixed",
  zIndex: 50,
  width: "100%",
  flexDirection: "row",
  backgroundColor: "#FFFFFF66",
  display: "flex",
  height: 70,
  alignItems: "center",
  overFlow: "hidden",
};
