import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, BlankLayout } from "./layouts";

import Privacy from "./views/Privacy";
import PrivacyOnly from "./views/PrivacyOnly";

export default [
    {
      exact: true,
      path: "/privacy/only",
      layout: BlankLayout,
      component: PrivacyOnly,
    },
    {
      exact: true,
      path: "/privacy",
      layout: DefaultLayout,
      component: Privacy,
    },
    {
      path: "*",
      layout: BlankLayout,
      component: () => <Redirect to="/privacy" />,
    }
  ];