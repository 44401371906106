import React from 'react';
import { Container, Row, Col } from 'shards-react';

const BlankLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid >
    <Row>
      <Col className="main-content" tag="main">
        {children}
      </Col>
    </Row>
  </Container>
);

export default BlankLayout;