import React from "react";
import "../App.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const PrivacyOnly = () => (
  <div style={{ ...TitleDivStyle, marginTop: 30}}>
    <div style={SingleContentDivStyle}>
      <p
        style={{
          ...HeaderTextStyle,
          textAlign: "start",
          width: "100%",
          paddingTop: 20
        }}
      >
        隱私條款
      </p>
      <PrivacyContent />
    </div>
  </div>
);

const PrivacyContent = () => (
  <div style={{ ...ContentTextStyle, fontSize: 16 }}>
    <span style={ContentTitleTextStyle}>隱私權聲明(APP)</span>
    <br />
    <TextDot
      text={
        "歡迎您使用杏保醫網體系數位服務，當您使用「杏保醫網行動服務系統」（下稱本APP）時，為了讓您能夠安全的使用本APP提供之各項服務與資訊，依據「個人資料保護法」之相關規定與立法精神，特此向您說明本APP的「隱私權保護及資訊安全」政策，請您詳閱下列內容，以保障您的權益："
      }
    />
    <span style={ContentTitleTextStyle}>個人資訊的收集與使用</span>
    <br />
    個人資訊是指可用以識別或聯絡特定個人的資料。<br />
    您使用本APP時，可能會要求您提供個人資訊。杏保醫網及其關係企業可能會在遵守本隱私權政策的前提下，相互共享本項個人資訊。也可能結合其他資訊，用於提供及改善公司產品、服務、內容及廣告。您不一定要依照我們的要求提供個人資訊，但如果您選擇不提供，會導致我們在許多情況下無法為您提供產品或服務，或是無法回應您提出的問題。<br />
    以下舉例說明杏保醫網可能收集的個人資訊類型及其使用方式。
    <br />
    <span style={ContentTitleTextStyle}>我們收集的個人資訊</span>
    <br />
    您在建立杏保醫網應用程式(App)、購買產品、下載軟體更新、聯絡我們或是參與線上問卷調查時，我們即可能收集各種資訊，包括您的姓名、通訊地址、電話號碼、電子郵件信箱、偏好聯絡方式等。<br />
    您使用杏保醫網產品與家人或朋友分享內容，或邀請他人參與杏保醫網服務或討論區時，杏保醫網即可能收集您提供的相關人等資訊，諸如姓名、通訊地址、電子郵件信箱及電話號碼等。杏保醫網將使用這些資訊來完成您的要求、提供相關產品或服務，或防範詐騙行為。
    <br />
    <span style={ContentTitleTextStyle}>我們如何使用您的個人資訊</span>
    <br />
    我們收集的個人資訊，可讓我們通知您杏保醫網最新的產品公告、軟體更新和即將舉行的活動，若您不希望列載於我們的郵寄列表，您可更新偏好設定隨時退出。<br />
    我們也可能會使用個人資訊來製作、開發、操作、提供並改善我們的產品、服務、內容和廣告，或是基於損失預防及反詐騙目的而使用個人資訊。<br />
    我們可能會使用包括您生日在內的個人資訊來驗證身分、協助辨識使用者以及確認適當的服務。例如，我們可能使用生日確認杏保醫網ID 帳戶持有人的年齡。<br />
    我們可能不定時使用個人資訊以寄送重要通知，例如有關消費及條款、條件和政策變更的通知。由於本項通知訊息對您與杏保醫網的互動十分重要，您無法取消接收本項通知。<br />
    我們也可能基於內部目的而使用個人資訊，例如進行稽核、資料分析及研究以改進杏保醫網的產品、服務及客戶溝通。<br />
    若您參加抽獎、競賽或類似促銷活動，我們也可能使用您提供的資訊進行這些活動。
    <br />
    <span style={ContentTitleTextStyle}>非個人資訊的收集與使用</span>
    <br />
    我們也會收集無法直接與任何特定個人建立關聯的資料。我們得為任何目的收集、使用、移轉及揭露非個人資訊。以下舉例說明我們收集的非個人資訊及其使用方式：<br />
    我們可能收集職業、語言、郵遞區號、區域號碼、唯一裝置識別碼、推薦連結網站、地點、以及杏保醫網產品使用地的時區等資訊，協助我們更深入瞭解客戶行為，改進我們的產品、服務及廣告。<br />
    我們也可能收集客戶在使用我們的網站、應用程式、以及其他產品和服務時的活動相關資訊。我們將彙總這類資訊，協助向客戶提供更實用的資訊，並瞭解公司網站、產品及服務的哪些部分最受關注。本隱私權政策將彚總資料視為非個人資訊。<br />
    我們可能收集和儲存您使用我們搜尋引擎等服務的詳細資訊。這些資訊可能用於改善服務提供結果的相關性。除了少數基於確保 Internet 服務品質的情況外，上述資訊不會與您的 IP 位址相關聯。<br />
    如果我們將非個人資訊及個人資訊合併在一起，則在前述資訊仍為合併的情況，該合併資訊將被視為是個人資訊。
    <br />
    <span style={ContentTitleTextStyle}>Cookie 及其他技術</span>
    <br />
    杏保醫網網站、線上服務、互動應用程式、電子郵件訊息及廣告可能使用「Cookie」及其他技術，例如像素標籤或網路信標。這類技術可協助我們更充分瞭解使用者行為，告訴我們使用者曾瀏覽過網站的哪些部分、並可促進及衡量廣告與網路搜尋的效益。然而，若當地法律將網路協定 (Internet Protocol，IP) 位址或其他類似的身分識別資料認定為個人資訊，則我們亦將其視為個人資訊。相同地，若非個人資訊與個人資訊合併，則在本隱私權政策中，我們也會將此類合併資訊視為個人資訊。<br />
    杏保醫網及其合作夥伴使用 Cookie 以及其他行動廣告服務技術，來控制您看到某個廣告的次數、播放您有興趣的廣告，以及評估廣告行銷活動的效果。<br />
    在您使用我們的網站、線上服務及應用程式時，杏保醫網及其合作夥伴也利用 Cookie 及其他技術記錄您的個人資訊，目標是讓您享有更便利、更個人化的杏保醫網應用程式體驗。舉例來說，如果我們得知您的名字，就能在您下次造訪杏保醫網應用程式時向您表示歡迎。如果得知您的國家和語言，就能協助我們提供量身打造且更實用的體驗。得知有人使用您的電腦或裝置購買特定產品或使用特定服務，可以讓我們提供更符合您興趣的廣告及電子郵件通訊內容。<br />
    我們和大多數 Internet 網站一樣，會自動收集部分資訊並將其儲存於記錄檔中。這類資訊包括網路通訊協定 (IP) 位址、瀏覽器類型及語言、網際網路服務供應商 (ISP)、轉介和離開網站和應用程式、作業系統、日期/時間戳記及點選流資料。<br />
    我們使用這類資訊瞭解及分析趨勢、管理網站、瞭解使用者在網站上的行為、改善我們的產品和服務，以及收集整體使用者的相關人口統計資訊。杏保醫網得使用前述資訊從事行銷及廣告服務。<br />
    在我們的電子郵件訊息中，有些會使用可連結至杏保醫網網站內容的「點選連結 URL」。客戶點選這類 URL 時，會先通過獨立的網頁伺服器，然後到達我們網站的目的地網頁。我們會追蹤這類點選連結資料，協助判定對特定主題的關注程度，並衡量客戶溝通的效益。若您不希望受到這種方式的追蹤，請勿點選電子郵件訊息的文字或圖片連結。<br />
    像素標籤可讓我們以客戶能夠讀取的格式傳送電子郵件訊息，並讓我們瞭解客戶是否開啟電子郵件。我們得使用這類資訊減少或停止傳送給客戶的訊息。
    <br />
    <span style={ContentTitleTextStyle}>向第三方揭露</span>
    <br />
    杏保醫網有時可能會將特定的個人資訊，提供給與杏保醫網合作供應產品及服務，或協助杏保醫網向客戶行銷的策略合作夥伴。例如若您購買並啟用 iPhone 時，即表示您授權杏保醫網以及您的電信業者可交換您在啟用過程期間所提供的資訊，藉以提供服務。若您獲准使用服務，您的帳戶將由杏保醫網以及您的電信業者各自的隱私權政策進行規範。杏保醫網僅會分享個人資訊以提供或改進我們的產品、服務及廣告，不會分享給第三方進行行銷。
    <br />
    <span style={ContentTitleTextStyle}>服務供應商</span>
    <br />
    杏保醫網會與提供合作的公司分享個人資訊：資訊處理、完成客戶訂單、向您交付產品、管理及加強客戶資料、提供客戶服務、評估您對產品及服務的興趣，以及執行客戶研究或滿意度調查。
    <br />
    <span style={ContentTitleTextStyle}>其他</span>
    <br />
    您居住地國內或國外的法律、法律程序、訴訟及/或公共和政府機關，可能會要求杏保醫網 必須揭露您的個人資訊。我們也可能基於國家安全、執法或其他重要公共議題，在判定為必要及適當的情況下，揭露您的個人資訊。<br />
    我們也可能為了執行公司的條款與條件或保障公司營運或使用者，在判定為合理必要的情況下揭露您的相關資訊。此外若發生公司重整、合併或出售等情況，我們得將收集的任何及所有個人資訊移轉給相關第三方。<br />
    當您開始使用 Google 服務，即表示您信賴Google對您個人資訊的處理方式。Google《隱私權政策》旨在協助您瞭解 Google 收集的資料類型、收集這些資料的原因以及這些資料的用途。這些事項相當重要，因此請撥冗至Google網站詳閱。提醒您，您可以前往 Google帳戶自行調整您的個人資訊管理設定以及隱私和安全保護設定。
    <br />
    <span style={ContentTitleTextStyle}>保護個人資訊</span>
    <br />
    杏保醫網非常重視您個人資訊的安全性。在資料傳輸期間，使用「傳輸層安全性」等加密技術來保護您的個人資訊。杏保醫網會使用具有存取限制的電腦系統來儲存您的個人資料，並會將這些電腦系統存放在具有實體安全措施的場所。<br />
    您在使用部分杏保醫網產品、服務或應用程式，或是透過杏保醫網討論區、聊天室或社交網路服務發表資訊時，其他使用者可閱讀、收集或使用您分享的個人資訊和內容。在這類情況下，您要為自己選擇分享或提交的個人資訊負責。例如若您在討論區文章列出自己的姓名和電子郵件地址，這些資訊就會公開。請小心使用這類功能。
    <br />
    <span style={ContentTitleTextStyle}>個人資訊的完整性及保留</span>
    <br />
    杏保醫網讓您輕鬆維持正確、完整且最新的個人資訊。我們將於完成本隱私權政策所述目的之必要期間保留您的個人資訊，除非法律規定或允許更長的保留期間。
    <br />
    <span style={ContentTitleTextStyle}>存取個人資訊</span>
    <br />
    您可在 杏保醫網 登入帳戶，協助確保正確、完整及最新的聯絡資訊和偏好設定。至於我們保留的其他個人資訊，我們會提供您存取權限，讓您可以基於任何目的存取這些資訊，包括要求我們更正不正確的資料，或是要求杏保醫網刪除依法或基於正當商業目的無需保留的資料。我們得拒絕處理您不重要/無意義的要求、危害他人隱私權的要求、極度不切實際的要求，或是當地法律並未規定的存取要求。您可透過區域隱私權聯絡表格提出存取、修正或刪除要求。
    <br />
    <span style={ContentTitleTextStyle}>以位置為基礎的服務</span>
    <br />
    為了在杏保醫網行動裝置提供以位置為基礎的服務，杏保醫網及其合作夥伴和獲授權人得收集、使用及分享精確的位置資料。以位置為基礎的服務可能使用 GPS、Bluetooth、IP 位址，搭配群眾外包 Wi-Fi 熱點、基地台位置及其他技術，來確定裝置的約略位置。除非您提供同意書，否則這類位置資料將以匿名方式收集，其格式無法辨識您的個人身分，並由杏保醫網及其合作夥伴和獲授權人用於提供及改善以位置為基礎的產品和服務。例如，您的裝置可能在您登入使用應用程式供應商的位置服務時，與應用程式供應商分享地理位置。<br />
    部分由杏保醫網提供的以位置為基礎的服務，需要您的個人資訊才能運作。您只要不使用以位置為基礎的功能，並關閉裝置和電腦上「位置服務」設定 (如適用)，即可撤銷同意杏保醫網及其合作夥伴和獲授權人收集、使用、傳輸、處理和維護位置和帳戶資料。
    <br />
    <span style={ContentTitleTextStyle}>行動裝置資源存取說明</span>
    <br />
    <div style={{width: "fit-content"}}>
      杏保醫網為了提供您更完善的服務及下列用途，將針對您的行動裝置存取以下項目：<br />
      <div style={{paddingBlockEnd: 5}}/>
      <table style={{margin: "auto"}}>
        <tr>
          <td style={{...TableContentStyle, background: "#e2efd9"}}>存取權限</td>
          <td style={{...TableContentStyle, background: "#e2efd9"}}>用途</td>
        </tr>
        <tr>
          <td style={TableContentStyle}>位置</td>
          <td style={TableContentStyle}>查詢鄰近院所</td>
        </tr>
        <tr>
          <td style={{...TableContentStyle, background: "#f2f2f2"}}>推播通知</td>
          <td style={{...TableContentStyle, background: "#f2f2f2"}}>各類排程通知</td>
        </tr>
      </table>
    </div>
    <br />
    <span style={ContentTitleTextStyle}>第三方網站及服務</span>
    <br />
    杏保醫網公司的網站、產品、應用軟體及服務可能含有連結至第三方網站、產品或服務的連結。我們產品與服務亦可能使用或提供第三方產品或服務，例如：第三方的購物券優惠資訊。第三方所收集的資料，可能包括諸如位置資料或詳細聯絡資料等的資料，均依據該第三方的隱私權實務為準。我們鼓勵您瞭解這些第三方的隱私權實施方式。
    <br />
    <span style={ContentTitleTextStyle}>公司全體對保護您隱私權的承諾</span>
    <br />
    為了確保您的個人資訊安全無虞，我們向杏保醫網員工傳達公司的隱私權和安全準則，並在公司內部嚴格執行隱私權防護措施。
    <br />
    <span style={ContentTitleTextStyle}>資訊安全措施與資料保護</span>
    <br />
    <TextDot
      text={
        "1.為了確保本APP之安全及持續營運，本網站主機均設有防火牆、防毒系統等相關資訊安全設備及必要的安全防護，對本網站內容及您的個人資料實施嚴格的保護。"
      }
    />
    <div style={{paddingBlockEnd: 5}}/>
    <TextDot
      text={
        "2.網路安全保護措施"
      }
    />
    <div style={{paddingBlockEnd: 5}}/>
    <ul style={{ paddingInlineStart: 30, margin: 0 , paddingBlockEnd: 5}}>
      (1)裝設防火牆設備，限制特定通訊埠的連結，防止非法入侵，以避免網站遭到非法使用，保障使用者的權益。
    </ul>
    <ul style={{ paddingInlineStart: 30, margin: 0 , paddingBlockEnd: 5}}>
      (2)利用弱點偵測軟體，不定期針對網路系統弱點掃描，並予以補強修正。
    </ul>
    <TextDot
      text={
        "3.任何未經授權而企圖破壞本APP機密性、完整性及可用性的行為均被禁止，且提醒您可能觸犯刑事等相關法律。"
      }
    />
    <div style={{paddingBlockEnd: 5}}/>
    <TextDot
      text={
        "4.本APP已提供相關保護措施保護您的個人資料。在您使用本APP各項服務時，您的個人資料在傳輸過程中，不會被他人非法擷取或入侵。"
      }
    />
    <span style={ContentTitleTextStyle}>隱私權保護及資訊安全政策之修正</span>
    <br />
    <ul style={{ paddingInlineStart: 20, margin: 0 }}>
      <li>本聲明得由杏保醫網因應需求適時進行修正，<font style={{color: "#ff0000"}}>將配合法令變動與因應最新技術，隨時更新本APP所提供的隱私權保護政策</font>，並刊登於本APP。</li>
    </ul>
    <span style={ContentTitleTextStyle}>隱私權保護及資訊安全政策之諮詢</span>
    <TextDot
      text={
        "若您對本聲明有任何疑問，或對所留存之個人資料有任何權利主張，歡迎隨時與本院聯絡。"
      }
    />
  </div>
);

const TextDot = (props) => (
  <ul style={{ paddingInlineStart: 20, margin: 0 }}>
    <li>{props.text}</li>
  </ul>
);

export default PrivacyOnly;

const HeaderTextStyle = {
  textAlign: "center",
  color: "#242424",
  fontSize: 24,
  fontFamily: "PingFangTC-Medium",
  margin: 0,
};

const TitleDivStyle = {
  transform: "translateY(-30px)",
  flex: 1,
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  display: "flex",
};

const SingleContentDivStyle = {
  minWidth: 345,
  flex: 1,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 20,
  marginTop: "auto",
  backgroundColor: "white",
};

const ContentTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Regular",
  fontSize: 13,
  marginLeft: 0,
  marginRight: 20,
  textAlign: "start",
  marginTop: 5,
};

const ContentTitleTextStyle = {
  fontSize: 18,
  fontWeight: 700,
  display: "inline-block",
  paddingTop: 20,
  paddingBottom: 20,
};

const TableContentStyle = {
  verticalAlign: "middle",
  textAlign: "center",
  width: 150,
}