const facebookId = "CymbalMedicalNetwork"
const googleLink = "https://play.google.com/store/apps/details?id=com.cmn_cymbal.android";
const facebookLink = "https://www.facebook.com/"+facebookId;
const appleLink = "https://apps.apple.com/us/app/facebook/id1542000385";
const emailLink = "mailto:service@cymbal-med.com.tw"
const facebookMessageLink = "https://m.me/"+facebookId;
const googleMapLink_xinCheng = "https://goo.gl/maps/Cw3PDJLhc5yDzirp9"
const googleMapLink_xinLin = "https://goo.gl/maps/SHCkHwqL4GsDWtAPA"
const googleMapLink_xinKang = "https://goo.gl/maps/FQtguAaYqjVB4ukY9"
export {googleLink, facebookLink, appleLink, emailLink, facebookMessageLink, googleMapLink_xinCheng, googleMapLink_xinLin, googleMapLink_xinKang};

