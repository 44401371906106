import React, { useState, useEffect } from "react";
import "../App.css";
import { Image, Button } from "react-bootstrap";
import { Scrollbar } from "react-scrollbars-custom";
import QRCode from "qrcode.react";
import PopUp from "../components/ContactPopup";
import imageBanner from "../images/banner.jpg";
import imageMan from "../images/f_01.png";
import imageWoman from "../images/f_02.png";
import imageAppleLight from "../images/appstore_l.png";
import imageGoogleLight from "../images/googleplay_l.png";
import imageGoogleMap from "../images/googleMap.jpg";
import { googleLink, appleLink, googleMapLink_xinCheng, googleMapLink_xinLin, googleMapLink_xinKang } from "../hooks/constant";
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Privacy = () => {
  const [mobileMode, setMobileMode] = useState(window.innerWidth < 500);
  const [open, setOpen] = useState(false);

  const handleRWD = () => {
    console.log(window.innerWidth < 500)
    setMobileMode(window.innerWidth < 500);
  };

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  useEffect(() => {
    window.addEventListener("resize", handleRWD);
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  return (
    <div className="App">
      <PopUp open={open} closeModal={closeModal} />
      <div>
        <HeaderImage openModal={openModal} mobileMode={mobileMode}/>
        <div style={ContentDivStyle}>
          <TitleView mobileMode={mobileMode} />
          <DivideLine mobileMode={mobileMode} />
          <div style={{ 
            ...TitleDivStyle, 
            marginTop: 20,
            transform: mobileMode ? "translateY(30px)" : "translateY(-30px)" 
            }}>
            <p
              style={{
                ...HeaderTextStyle,
                textAlign: "start",
                width: "100%",
                marginLeft: 25,
              }}
            >
              下載掛號APP來使用吧！
            </p>
          </div>
          <DownloadView mobileMode={mobileMode} />
          <DivideLine mobileMode={mobileMode} />
          <ContentView mobileMode={mobileMode} />
          <div>
            <p style={{ 
              ...ContentTextStyle, 
              textAlign: "center",
              marginTop: mobileMode ? 30 : 5 
              }}>
              Copyright Ⓒ 2020 杏保醫網股份有限公司 版權所有
            </p>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#50BE00", height: 8 }} />
    </div>
  );
};

const HeaderImage = ({openModal, mobileMode}) => (
  <div style={HeaderDivStyle}>
    <Image src={imageBanner} style={HeaderImageStyle} />
    <div style={{
      ...HeaderTextsDivStyle,
      transform: mobileMode ? "translate(-50%, -20%)" : "translate(-50%, -50%)",
    }}>
      <p style={HeaderTextStyle}>歡迎來到杏保醫網</p>
      <p style={HeaderSecondTextStyle}>社區醫療的守護者</p>
      <Button
        variant="success"
        style={HeaderButtonStyle}
        onClick={openModal}
      >
        聯絡我們
      </Button>
      <p style={{
        ...HeaderThirdTextStyle,
        backgroundColor: mobileMode ? "#F5F5F5" : "#F5F5F500",
        marginTop: mobileMode ? 15 : 30,
        marginBottom: mobileMode ? 70 : 0,
        paddingTop: mobileMode ? 15 : 0,
        paddingBottom: mobileMode ? 15 : 0,
        }}>
      <font style={{color: "#ff0000"}}>杏保醫網全新網站試營運中！</font>
      <br/>
      <font>本網站將於3月31日進行轉移</font> 
      <br/>
      <font>可先點此前往新建</font>
      <a href="https://www2.cymbal-med.com.tw/zh-tw/">杏保醫網</a>
      </p>
    </div>
  </div>
);

const TitleView = (props) => (
  <div style={{
    ...TitleDivStyle,
    transform: props.mobileMode ? "translateY(80px)" : "translateY(-30px)",
    }}>
    <div style={SingleTitleManDivStyle}>
      <div style={ShadowDivStyle}>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Image src={imageMan} style={ManImageStyle} />
          <div style={{ width: 130 }}></div>
          <div style={{ flex: 1 }}>
            <p
              style={{
                ...TitleTextStyle,
                fontSize: props.mobileMode ? 16 : 18,
              }}
            >
              關於我們
            </p>
            <div
              style={{
                ...TitleSecondTextStyle,
                fontSize: props.mobileMode ? 13 : 16,
              }}
            >
              杏保醫網目的在於協助基層診所提供完善的健康照護，使民眾獲得便利、品質可靠的在地化服務，合作診所以慢性病為核心，並視地區特性納入特色科別。
              <br />
              <p>我們將致力成為民眾選擇基層診所時最信賴的品牌。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={SingleTitleWomanDivStyle}>
      <div style={ShadowDivStyle}>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            flexDirection: "row",
          }}
        >
          <Carousel
              autoPlay={true}
              interval={3000}
              infiniteLoop={true}
              showThumbs={false}
              stopOnHover={true}
              showArrows={false}
              showStatus={false}
            >
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    ...TitleTextStyle,
                    fontSize: props.mobileMode ? 16 : 18,
                  }}
                >
                  合作診所
                </p>
                <div
                  style={{
                    ...TitleSecondTextStyle,
                    fontSize: props.mobileMode ? 13 : 16,                 
                  }}
                >
                  杏保醫網信誠診所
                  <br />
                  電話：02-2706-8208
                  <br />
                  <div onClick={() => {window.open(googleMapLink_xinCheng);}}>
                  地址：台北市大安區信義路四段197號{" "}               
                  <Image
                    style={{ width: 30, height: 30, position:"fixed",marginLeft:5 }}
                    src={imageGoogleMap}
                  ></Image>
                  </div>
                  <br />
                  主治項目：心血管疾病/肝膽腸胃/內分泌/三高/失眠焦慮/感冒過敏/復健/成人健檢/自費疫苗
                  <br />
                  門診時段：
                  <br />
                  <ul style={{ paddingInlineStart: 20, margin: 0 }}>
                    <li style={{ display: "contents" }}>早08:30~11:30</li>
                    <br />
                    <li style={{ display: "contents" }}>午13:30~16:30</li>
                    <br />
                    <li style={{ display: "contents" }}>晚18:00~21:00</li>
                  </ul>
                  <br />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    ...TitleTextStyle,
                    fontSize: props.mobileMode ? 16 : 18,
                  }}
                >
                  合作診所
                </p>
                <div
                  style={{
                    ...TitleSecondTextStyle,
                    fontSize: props.mobileMode ? 13 : 16,                 
                  }}
                >
                  杏保醫網信霖診所
                  <br />
                  電話：(03)532-6333
                  <br />
                  <div onClick={() => {window.open(googleMapLink_xinLin);}}>
                  地址：新竹市武陵路189號1-2樓(國泰新竹健康園區){" "}
                  <Image
                    style={{ width: 30, height: 30, position:"fixed",marginLeft:5 }}
                    src={imageGoogleMap}
                  ></Image>
                  </div>
                  <br />
                  主治項目：心血管/內分泌/三高困擾/肝膽腸胃/甲狀腺問題/腎臟疾病/血液透析/感冒過敏/成人健檢/小兒保健/自費疫苗/抽血驗尿/超音波/心電圖/復健物理治療/體外震波
                  <br />
                  門診時段：
                  <br />
                  <ul style={{ paddingInlineStart: 20, margin: 0 }}>
                    <li style={{ display: "contents" }}>早09:00~12:00</li>
                    <br />
                    <li style={{ display: "contents" }}>午14:00~17:00</li>
                    <br />
                    <li style={{ display: "contents" }}>晚18:00~21:00</li>
                  </ul>
                  <br />
                </div>
              </div>   
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    ...TitleTextStyle,
                    fontSize: props.mobileMode ? 16 : 18,
                  }}
                >
                  合作診所
                </p>
                <div
                  style={{
                    ...TitleSecondTextStyle,
                    fontSize: props.mobileMode ? 13 : 16,                 
                  }}
                >
                  杏保醫網信康診所
                  <br />
                  電話：02-2377-1363
                  <br />
                  <div onClick={() => {window.open(googleMapLink_xinKang);}}>
                  地址：台北市敦化南路二段335號{" "}               
                  <Image
                    style={{ width: 30, height: 30, position:"fixed",marginLeft:5 }}
                    src={imageGoogleMap}
                  ></Image>
                  </div>
                  <br />
                  主治項目：血液透析/腎臟疾病/三高/一般內科
                  <br />
                  洗腎透析時段：
                  <br />
                  <ul style={{ paddingInlineStart: 20, margin: 0 }}>
                    <li style={{ display: "contents" }}>早07:00~11:30</li>
                    <br />
                    <li style={{ display: "contents" }}>午12:30~16:30</li>
                    <br />
                    <li style={{ display: "contents" }}>晚17:30~22:00</li>
                  </ul>
                  <br />
                </div>
              </div>        
          </Carousel>
          <div style={{ width: 155 }} />
          <Image src={imageWoman} style={WomanImageStyle} />   
        </div>
      </div>
    </div>
  </div>
);

const DivideLine = ({mobileMode}) => (
  <div style={{ 
    ...TitleDivStyle, 
    marginTop: 30,
    transform: mobileMode ? "translateY(30px)" : "translateY(-30px)" 
    }}>
    <div style={DivisionLineDivStyle}></div>
    <div style={DivisionLineDivStyle}></div>
  </div>
);

const DownloadView = ({mobileMode}) => (
  <div style={{ 
    ...TitleDivStyle, 
    marginTop: 30,
    transform: mobileMode ? "translateY(30px)" : "translateY(-30px)" 
    }}>
    <div style={SingleDownloadDivStyle}>
      <div style={DownLoadQRCodeDiv}>
        <QRCode
          value={appleLink}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div style={DownLoadButtonDivStyle}>
        <div
          style={{
            flexDirection: "column",
            width: 160,
          }}
        >
          <Image
            src={imageAppleLight}
            style={{
              width: 160,
              height: 50,
              objectFit: "cover",
              flex: 1,
            }}
          />
          <Button
            variant="success"
            style={DownloadButtonStyle}
            onClick={() => {
              window.open(appleLink);
            }}
          >
            前往下載
          </Button>
        </div>
      </div>
    </div>
    <div style={SingleDownloadDivStyle}>
      <div style={DownLoadQRCodeDiv}>
        <QRCode
          value={googleLink}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div style={DownLoadButtonDivStyle}>
        <div
          style={{
            flexDirection: "column",
            width: 160,
          }}
        >
          <Image
            src={imageGoogleLight}
            style={{
              width: 160,
              height: 50,
              objectFit: "cover",
              flex: 1,
            }}
          />
          <Button
            variant="success"
            style={DownloadButtonStyle}
            onClick={() => {
              window.open(googleLink);
            }}
          >
            前往下載
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const ContentView = ({mobileMode}) => (
  <div style={{ 
    ...TitleDivStyle, 
    marginTop: 30,
    transform: mobileMode ? "translateY(30px)" : "translateY(-30px)" 
    }}>
    <div style={SingleContentDivStyle}>
      <p
        style={{
          ...HeaderTextStyle,
          textAlign: "start",
          width: "100%",
        }}
      >
        隱私權政策
      </p>
      <Scrollbar style={{ height: 250, color: "#50BE00", marginTop: 20 }}>
        <PrivacyContent />
      </Scrollbar>
    </div>
  </div>
);

const PrivacyContent = () => (
  <div style={{ ...ContentTextStyle, fontSize: 16 }}>
    非常歡迎您光臨「杏保醫網」網站（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
    <br />
    <span style={ContentTitleTextStyle}>一、隱私權保護政策的適用範圍</span>
    <br />
    隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
    <br />
    <span style={ContentTitleTextStyle}>
      二、個人資料的蒐集、處理及利用方式
    </span>
    <br />
    <TextDot
      text={
        "當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。"
      }
    />
    <br />
    <TextDot
      text={
        "本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。"
      }
    />
    <br />
    <TextDot
      text={
        "於一般瀏覽時，伺服器會自行紀錄相關行徑，包括但不限於您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料紀錄等，做為我們增進網站服務的參考依據，此紀錄為內部應用，決不對外公佈。"
      }
    />
    <br />
    <TextDot
      text={
        "為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。"
      }
    />
    <br />
    <TextDot
      text={
        "除法規要求更長的保留期間外，本網站僅於本隱私權保護政策所述目的之必要期間內保留您的個人資料。您不一定要依照本公司的要求提供個人資料，若您未能提供相關個人資料時，將可能導致本網站在許多情況下無法為您提供產品或完善的服務，或是無法回應您提出的問題。"
      }
    />
    <br />
    <span style={ContentTitleTextStyle}>三、資料之保護</span>
    <br />
    <TextDot
      text={
        "本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。"
      }
    />
    <br />
    <TextDot
      text={
        "如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。"
      }
    />
    <br />
    <span style={ContentTitleTextStyle}>四、網站對外的相關連結</span>
    <br />
    本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
    <br />
    <span style={ContentTitleTextStyle}>五、與第三人共用個人資料之政策</span>
    <br />
    本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
    <br />
    前項但書之情形包括不限於：
    <br />
    <br />
    <TextDot text={"經由您書面同意。"} />
    <br />
    <TextDot text={"法律明文規定。"} />
    <br />
    <TextDot text={"為免除您生命、身體、自由或財產上之危險。"} />
    <br />
    <TextDot
      text={
        "與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。"
      }
    />
    <br />
    <TextDot
      text={
        "當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。"
      }
    />
    <br />
    <TextDot text={"有利於您的權益。"} />
    <br />
    <TextDot
      text={
        "本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。"
      }
    />
    <br />
    <span style={ContentTitleTextStyle}>六、Cookies的運用與政策</span>
    <br />
    為了提供您更好、更個人化的服務，以及方便您參與個人化的互動活動，本公司透過Cookies來蒐集您在本網站上瀏覽或查詢的相關資料。Cookies是伺服端為了區別使用者的不同喜好，經由瀏覽器寫入使用者硬碟的一些簡短資訊。您可以在瀏覽器設定中選擇修改對Cookies的接受程度(瀏覽器設定建議)，包括接受所有Cookies、設定Cookies時得到通知、拒絕所有Cookies等三種。如果您選擇拒絕所有的Cookies，您可能無法使用部分個人化服務，或是參與部分活動。
    <br />
    此外，為提升使用經驗，在您瀏覽本網站時，本公司將會作成紀錄以分析本網站的訪客人數、一般使用模式及您的個人使用模式，本公司亦可能透過第三方或使用第三方技術來調查網站流量與您於本網站上的其他活動，以(1)蒐集更多關於本網站訪客的資料，包括用戶分佈、其行為及使用模式；(2)作出更準確的報告；及(3)提高本公司市場推廣效率。第三方蒐集資料並彙整後將提供本公司，惟本公司不會為此調查而向或從第三方提供或得到可辨別您身分的個人資料。
    <br />
    本網站已安裝Google calendar功能，可連結您的Google帳號，讓您的預約紀錄出現在Google行事曆，這會讓本網站和Google分享關於您的資訊。您可以選擇不使用此功能，但同時您將無法享有本服務。
    <br />
    本網站提供杏保醫網掛號APP下載連結，APP提供掛號、門診表/門診公告、掛號紀錄等功能，並可即時查詢看診進度。以身分證字號及生日註冊登入可享受個人化服務，快速預約及設定看診提醒。
    <br />
    <span style={ContentTitleTextStyle}>七、傳送商業資訊或電子郵件之政策</span>
    <br />
    為了增加整體服務內容，本網站所傳送的內容可能包括非本公司所提供的服務或其他活動訊息，本公司將會在活動時提供充分說明，您可以自由選擇是否接受這項特定服務或活動。當您瀏覽相關活動訊息時，在未徵得同意前，本公司保證不會將您在本網站或本公司所登錄的任何個人資料轉送給第三方。本網站並將於事前或註冊登錄取得您的同意後，傳送商業性資料或電子郵件給您，本公司除了會在該資料或電子郵件上註明發送者，也會在該資料或電子郵件上提供您能隨時停止接收這些資料或電子郵件的方法、說明或功能連結。
    <br />
    <span style={ContentTitleTextStyle}>八、向第三方揭露</span>
    <br />
    本公司可能會在您同意之情況下，將特定的個人資料提供給與本公司合作供應產品及服務的第三方，以進行資訊處理、完成訂單、交付產品、提供客戶服務以及執行客戶滿意度調查，但不包括第三方利用該個人資料進行商業行銷行為。
    <br />
    <span style={ContentTitleTextStyle}>九、自我保護措施</span>
    <br />
    本公司非常重視您個人資料的安全性並善盡保護責任。本網站以SSL（Secure Socket
    Layer）加密技術來保護您所提供資訊的安全，避免在傳輸時被第三人截取。本公司致力於提供安全的資訊環境【包括但不限於建立防火牆、加密技術、使用者辨識系統（例如密碼及個人帳號）及存取控制機制等】，以控制個人資料之存取。
    <br />
    <span style={ContentTitleTextStyle}>十、隱私權保護承諾</span>
    <br />
    為了確保您的個人資料安全無虞，本公司已向內部員工傳達上述隱私權及安全準則，並在本公司內部嚴格執行隱私權防護措施。
    <br />
    <span style={ContentTitleTextStyle}>十一、隱私權保護政策之修正</span>
    <br />
    本公司為保護使用者個人資料、維護網路隱私，將配合法令變動與因應最新技術，隨時更新本網站所提供的隱私權保護政策，以落實保障您的隱私權。建議您於再次來訪時，可重新瞭解本公司的最新隱私權保護政策及其改變。
    <br />
    <span style={ContentTitleTextStyle}>十二、消費者聯絡資訊</span>
    <br />
    如您對於本隱私權保護政策或個人資料運用相關事項有任何疑問時，歡迎
    <span style={{ fontWeight: 700 }}>於再次來訪時洽詢相關人員</span>
    ，本公司當竭誠為您服務。
    <br />
  </div>
);

const TextDot = (props) => (
  <ul style={{ paddingInlineStart: 20, margin: 0 }}>
    <li>{props.text}</li>
  </ul>
);

export default Privacy;

const HeaderDivStyle = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const HeaderImageStyle = {
  //   position: "absolute",
  width: "100%",
  minHeight: 313,
  objectFit: "cover",
};

const HeaderTextsDivStyle = {
  position: "absolute",
  zIndex: 10,
  width: "100%",
  transform: "translate(-50%, -50%)",
  left: "50%",
  top: "50%",
};
const HeaderTextStyle = {
  textAlign: "center",
  color: "#242424",
  fontSize: 24,
  fontFamily: "PingFangTC-Medium",
  margin: 0,
};

const HeaderSecondTextStyle = {
  textAlign: "center",
  color: "#242424",
  fontSize: 16,
  fontFamily: "PingFangTC-Regular",
  marginTop: 5,
};

const HeaderThirdTextStyle = {
  textAlign: "center",
  color: "#242424",
  fontSize: 20,
  fontFamily: "PingFangTC-Regular",
};

const HeaderButtonStyle = {
  marginTop: 30,
  width: 157,
  height: 42,
  backgroundColor: "#50BE00",
  borderRadius: 8,
  borderWidth: 0,
  fontFamily: "PingFangTC-Medium",
  fontSize: 18,
  color: "white",
};

const ContentDivStyle = {
  zIndex: 20,
  width: "90%",
  marginLeft: "5%",
  marginRight: "5%",
};

const TitleDivStyle = {
  transform: "translateY(-30px)",
  flex: 1,
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  display: "flex",
};

const SingleTitleManDivStyle = {
  minWidth: 345,
  flex: 1,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 20,
  flexDirection: "row",
  flexWrap: "wrap",
  display: "flex",
  position: "relative",
  justifyContent: "start",
  alignItems: "flex-end",
};

const SingleTitleWomanDivStyle = {
  minWidth: 345,
  flex: 1,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 20,
  flexDirection: "row",
  flexWrap: "wrap",
  display: "flex",
  position: "relative",
  justifyContent: "flex-end",
  alignItems: "flex-end",
};

const ManImageStyle = {
  width: 130,
  height: 200,
  objectFit: "cover",
  marginLeft: 10,
  marginRight: 2,
  position: "absolute",
  bottom: 0,
};

const WomanImageStyle = {
  width: 155,
  height: 191,
  objectFit: "cover",
  marginLeft: 2,
  marginRight: 10,
  position: "absolute",
  bottom: 0,
  right: 2,
};

const ShadowDivStyle = {
  boxShadow: "0 0 20px 0 rgba(0,0,0,0.10)",
  borderRadius: 16,
  flex: 1,
  backgroundColor: "white",
  width: "100%",
  minHeight: 180,
  zIndex: 10,
  flexDirection: "row",
  display: "flex",
  height: "100%",
};

const TitleTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Medium",
  fontWeight: 700,
  fontSize: 16,
  marginLeft: 20,
  textAlign: "start",
  marginBottom: 0,
};

const TitleSecondTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Regular",
  fontSize: 13,
  marginLeft: 20,
  marginRight: 20,
  textAlign: "start",
  marginTop: 5,
};

const DivisionLineDivStyle = {
  //   position: "absolute",
  minWidth: 345,
  flex: 1,
  height: 1,
  marginLeft: 25,
  marginRight: 25,
  //   marginTop: 30,
  backgroundColor: "#DCDCDC",
};

const SingleDownloadDivStyle = {
  minWidth: 345,
  flex: 1,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 20,
  flexDirection: "row",
  flexWrap: "wrap",
  display: "flex",
  position: "relative",
  justifyContent: "start",
  alignItems: "flex-end",
  boxShadow: "0 0 20px 0 rgba(0,0,0,0.10)",
  borderRadius: 16,
  backgroundColor: "white",
};

const DownLoadQRCodeDiv = {
  marginTop: 34,
  marginBottom: 34,
  marginLeft: 40,
  width: 112,
  height: 112,
};

const DownLoadButtonDivStyle = {
  marginTop: 34,
  marginBottom: 34,
  height: 112,
  justifyContent: "center",
  flex: 1,
  display: "flex",
};

const DownloadButtonStyle = {
  width: 160,
  height: 42,
  marginTop: 20,
  backgroundColor: "#50BE00",
  borderRadius: 8,
  borderWidth: 0,
  fontFamily: "PingFangTC-Medium",
  fontSize: 18,
  color: "white",
};

const SingleContentDivStyle = {
  minWidth: 345,
  flex: 1,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 20,
  marginTop: "auto",
  backgroundColor: "white",
};

const ContentTextStyle = {
  color: "#242424",
  fontFamily: "PingFangTC-Regular",
  fontSize: 13,
  marginLeft: 0,
  marginRight: 20,
  textAlign: "start",
  marginTop: 5,
};

const ContentTitleTextStyle = {
  fontSize: 18,
  fontWeight: 700,
  display: "inline-block",
  paddingTop: 20,
  paddingBottom: 20,
};
