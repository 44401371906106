import React from "react";
import routes from "./routes";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )}
              />
            ))}
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
